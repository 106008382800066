<template>
  <div class="app-container createArticle">
    <div class="editor">
      <el-form ref="form" label-width="90px" :model="form" :rules="rules">
        <el-form-item label="公告标题:" prop="title">
          <el-input style="width: 240px" size="small" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="公告详情:" prop="content">
          <Editor
            id="basisEditor"
            :val.sync="form.content"
            placeholder="请输入"
            :height="600"
            serverUrl="serverUrl"
            useObjectStr="CONTENT_UEDITOR_FILE"
            :folderPath="'10'"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <el-button size="small" @click="$router.go(-1)"> 取消</el-button>
      <el-button size="small" @click="savToDrafts" v-if="id"> 保存</el-button>
      <el-button size="small" @click="savToDrafts" v-if="!id">保存到草稿</el-button>
      <el-button size="small" type="primary" @click="issueNotice"> 发布</el-button>
    </div>
    <!-- 发布公告对话框 -->
    <el-dialog
      title="发布公告"
      :visible="noticeVisible"
      width="800px"
      :before-close="noticeHandleClose"
      class="noticeWarpper"
      @open="onopen"
    >
      <div class="articleContent">
        <el-form
          :rules="sizeFormRule"
          label-suffix="："
          ref="sizeForm"
          :model="sizeForm"
          label-width="100px"
          size="small"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="公告分类" prop="categoryId">
                <el-select clearable style="width: 240px" v-model="sizeForm.categoryId" placeholder="请选择公告分类">
                  <el-option v-for="v in noticeCate" :key="v.id" :label="v.categoryName" :value="v.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公告时间" prop="date">
                <span style="margin-right: 20px">开始时间</span>
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  v-model="sizeForm.date"
                  style="width: 204px"
                  :picker-options="pickerOptionsLimit"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="45px" prop="time">
                <el-time-picker
                  value-format="HH-mm-ss"
                  placeholder="选择时间"
                  v-model="sizeForm.time"
                  style="width: 160px"
                ></el-time-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="date2">
                <span style="margin-right: 20px">结束时间</span>
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  v-model="sizeForm.date2"
                  style="width: 204px"
                  :picker-options="pickerOptionsLimit"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="45px" prop="time2">
                <el-time-picker
                  value-format="HH-mm-ss"
                  placeholder="选择时间"
                  v-model="sizeForm.time2"
                  style="width: 160px"
                ></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公告展示方式" prop="displayMode" label-width="128px">
                <el-radio-group v-model="sizeForm.displayMode" size="medium">
                  <el-radio :label="1">单次弹出</el-radio>
                  <el-radio :label="2">每次登录弹出</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="noticeHandleClose">取 消</el-button>
        <LoadingBtn size="small" type="primary" @click="submit">发 布</LoadingBtn>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  noticeContentCreate,
  noticeContentDetail,
  noticeCategoryList,
  noticeContentUpdate
} from '@/api/helpcenter/helpcenter'
import { formatDate } from 'element-ui/src/utils/date-util'
import Editor from '@/components/editor'
import { REQUEST_ALL_DATA } from '@/utils/constant'

export default {
  components: {
    Editor
  },
  data() {
    return {
      // 文章标题和内容的表单--------------------------
      form: {
        title: '',
        content: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' },
          { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
        ],
        content: [{ required: true, message: '请输入公告详情', trigger: 'blur' }]
      },
      // end--------------------------------------------
      // 发布弹出框表单内容--------------------------
      sizeForm: {
        categoryId: '',
        displayMode: 1,
        date: '',
        time: '',
        date2: '',
        time2: '',
        releaseTime: '',
        endTime: ''
      },
      sizeFormRule: {
        categoryId: [{ required: true, message: '请选择公告分类', trigger: 'blur' }],
        date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        date2: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        time2: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        displayMode: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      noticeVisible: false,
      noticeCate: [],
      loading: false,
      pickerOptions: {
        selectableRange: null
      },

      //日期限制
      pickerOptionsLimit: {
        disabledDate(time) {
          const date = formatDate(new Date(time), 'yyyy-MM-dd')
          const nowDate = formatDate(new Date(), 'yyyy-MM-dd')
          return new Date(date).getTime() < new Date(nowDate).getTime()
        }
      }
    }
  },
  async created() {
    if (this.$route.query.id) {
      try {
        const { code, detail } = await noticeContentDetail({ id: this.$route.query.id })
        if ($SUC({ code })) {
          const { title, content } = detail || {}
          Object.assign(this.form, {
            title,
            content
          })
        } else {
          this.$message.error('获取数据失败')
        }
      } catch (e) {
        this.$message.error('获取数据失败')
      }
    }
    try {
      const { code, detail } = await noticeCategoryList({ ...REQUEST_ALL_DATA,
        orderItems: [
          { asc: true, column: 'sort_number' },
          { asc: true, column: 'id' }
        ] 
      })
      if ($SUC({ code })) {
        this.noticeCate = detail
      } else {
        this.$message.error('获取分类失败')
      }
    } catch (e) {
      this.$message.error('获取分类失败')
    }
  },
  computed: {
    startDateTime({ sizeForm: { date, time } }) {
      return date + ' ' + time.replace(/-/g, ':')
    },

    endDateTime({ sizeForm: { date2, time2 } }) {
      return date2 + ' ' + time2.replace(/-/g, ':')
    },

    startDateTimeStamp({ startDateTime }) {
      return +new Date(startDateTime)
    },

    endDateTimeStamp({ endDateTime }) {
      return +new Date(endDateTime)
    },
   
    endPickerOptions({sizeForm:{time}}) {
      return {
        selectableRange: `${time.replace(/-/g, ':')} - 23:59:59`
      }
    },

    id({ $route }) {
      return $GET($route, 'query.id', null)
    },

    options({id}){
      if(id){
        return{
          api:noticeContentUpdate,
          successTxt:'发布成功',
          failTxt: '发布失败',
          getParams(){
            const {
            form: { content, title },
              sizeForm:{
                categoryId,
                displayMode,
              },
              startDateTime: releaseTime,
              endDateTime: endTime,
            } = this
            return{
              id,
              categoryId,
              displayMode,
              releaseTime,
              endTime,
              content,
              title,
              status:3,
              releaseType:2,
            }
          }
        }
      }
      return{
        api:noticeContentCreate,
        successTxt:'发布成功',
        failTxt: '发布失败',
        getParams(){
          const {
            form: { content, title },
              sizeForm:{
                categoryId,
                displayMode,
              },
              startDateTime: releaseTime,
              endDateTime: endTime,
            } = this
            return {
              categoryId,
              displayMode,
              content,
              title,
              releaseTime,
              endTime,
              status: 3,
            releaseType: 2
            }
        }
      }
    },
    option({ id }) {
      if (id) {
        return {
          api: noticeContentUpdate,
          successTxt: '保存成功',
          failTxt: '保存失败',
          getParams() {
            const {
              form: { content, title },
            } = this
            return {
              id,
              content,
              title,
              status: 1,
            }
          }
        }
      }
      return {
        api: noticeContentCreate,
        successTxt: '保存到草稿箱成功',
        failTxt: '保存到草稿箱失败',
        getParams() {
          const {
            form: { content, title }
          } = this
          return {
            // status为1表示发布到草稿
            status: 1,
            content,
            title
          }
        }
      }
    }
  },
  watch: {
    noticeVisible: {
      handler(n) {
        if (n) {
          this.fillTime(), 
          this.endTime()
        }
      }
    }
  },
  methods: {
    // 将当前时间填入表单
    fillTime() {
      const today = new Date()
      this.sizeForm.date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const date = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date.getHours()
      const mm = date.getMinutes().toString().padStart(2, '0')
      const ss = date.getSeconds().toString().padStart(2, '0')
      this.sizeForm.time = `${hh}-${mm}-${ss}`
    },

    // 将结束时间填入表单
    endTime() {
      const today = new Date()
      this.sizeForm.date2 = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const date2 = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date2.getHours()
      const mm = date2.getMinutes().toString().padStart(2, '0')
      const ss = date2.getSeconds().toString().padStart(2, '0')
      this.sizeForm.time2 = `${hh}-${mm}-${ss}`
    },

    selectableRange() {
      const today = new Date()
      const t = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const t1 = new Date(this.sizeForm.date)
      const t2 = new Date(t)
      console.log(today)
      console.log('t1-t2', t1, t2)
      if (t1 - t2 >= 86400000) return '00:00:00 - 23:59:59'
      const date = new Date(Date.now() + 300000) //5分钟之后的时间
      const hh = date.getHours()
      const mm = date.getMinutes().toString().padStart(2, '0')
      const ss = date.getSeconds().toString().padStart(2, '0')
      return `${hh}:${mm}:${ss} - 23:59:59`
    },

    // 验证时间选择在5分钟之后,并且结束时间大于开始时间
    validateTime() {
      const date = new Date(Date.now() )
      const { startDateTimeStamp, endDateTimeStamp } = this
      if(startDateTimeStamp - date.getTime()< 300000){
        this.$message.error('开始时间必须在当前5分钟之后')
        return false
      }
      if (endDateTimeStamp <= startDateTimeStamp) {
        this.$message.error('结束时间必须大于开始时间，请重新设置后发布')
        return false
      }
      return true
    },

    noticeHandleClose() {
      this.noticeVisible = false
      this.$refs['sizeForm'].resetFields()
    },
    issueNotice() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.noticeVisible = true
        } else {
          return false
        }
      })
    },
    // 保存到草稿
    async savToDrafts() {
      try {
        this.$refs['form'].validate()
      } catch {
        return false
      }
      try {
        this.loading = true
        const { api, getParams, successTxt, failTxt } = this.option
        const { code } = await api(getParams.call(this))
        if (!$SUC({ code })) return this.$message.error(failTxt)
        this.$message.success(successTxt)
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },

    // 确定发布
    async submit() {
      if (!this.validateTime()) return
      try {
        await this.$refs['sizeForm'].validate()
      } catch (err) {
        return false
      }

      try {
        this.loading = true
        const { api, getParams, successTxt, failTxt } = this.options
        const { code } = await api(getParams.call(this))
        if (!$SUC({ code })) return this.$message.error(failTxt)
        this.$message.success(successTxt)
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
      // const {
      //   sizeForm: { categoryId, displayMode },
      //   startDateTime: releaseTime,
      //   endDateTime: endTime,
      //   form: { content, title }
      // } = this
      // try {
      //   const data = {
      //     categoryId,
      //     displayMode,
      //     content,
      //     title,
      //     releaseTime,
      //     endTime,
      //     status: 3,
      //     releaseType: 2
      //   }
      //   const { code } = await noticeContentCreate(data)
      //   if (!$SUC({ code })) return this.$message.error('发布失败')
      //   this.$message.success('发布成功')
      //   this.$router.go(-1)
      // } catch (e) {
      //   this.$message.error('发布失败')
      // } finally {
      //   this.loading = false
      // }
    },
     onopen() {
      this.pickerOptions.selectableRange = this.selectableRange()
     }
  }
}
</script>

<style lang="scss" scoped>
.createArticle {
  position: relative;
  padding: 20px 0px;

  .editor {
    padding: 0 346px 0 15px;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px 1px #999;
  }

  .noticeWarpper {
    ::v-deep {
      .el-dialog__title {
        font-size: 14px;
        color: #1a1a1a;
      }

      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
    }

    .articleContent {
      height: 400px;

      .el-form {
        .el-form-item {
          margin-bottom: 32px;
        }

        .datePickNotice {
          margin-top: -15px;
          padding-left: 170px;
          color: #595961;
        }
      }
    }
  }
}
</style>
