import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 帮助中心分类创建
export function helpCenterCategoryCreate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterCategoryCreate',
    method: 'post',
    data
  })
}
// 帮助中心分类创建
export function helpCenterCategoryList(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterCategoryList',
    method: 'post',
    data
  })
}
// 帮助中心分类删除
export function helpCenterCategoryDelete(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterCategoryDelete',
    method: 'post',
    data
  })
}
// 帮助中心分类修改
export function helpCenterCategoryUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterCategoryUpdate',
    method: 'post',
    data
  })
}
// 帮助中心分类排序
export function helpCenterCategoryChangeSort(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterCategoryChangeSort',
    method: 'post',
    data
  })
}
// 帮助中心内容排序
export function helpCenterContentChangeSort(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentChangeSort',
    method: 'post',
    data
  })
}
// 文章管理--------------------------------------

// 文章管理删除
export function helpCenterContentDelete(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentDelete',
    method: 'post',
    data
  })
}

// 文章移动分类
export function helpCenterContentChangeCategory(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentChangeCategory',
    method: 'post',
    data
  })
}

// 文章详情
export function helpCenterContentDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentDetail',
    method: 'post',
    data
  })
}
// 文章修改
export function helpCenterContentUpdate(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentUpdate',
    method: 'post',
    data
  })
}
// 文章创建
export function helpCenterContentCreate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/helpCenterContentCreate',
    method: 'post',
    data
  })
}

// 公告分类----------------------------------------------

// 公告分类创建
export function noticeCategoryCreate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeCategoryCreate',
    method: 'post',
    data
  })
}

// 公告分类列表
export function noticeCategoryList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeCategoryList',
    method: 'post',
    data
  })
}

// 公告分类编辑
export function noticeCategoryUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeCategoryUpdate',
    method: 'post',
    data
  })
}

// 公告分类删除
export function noticeCategoryDelete(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeCategoryDelete',
    method: 'post',
    data
  })
}

// 公告移动排序
export function noticeCategoryChangeSort(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeCategoryChangeSort',
    method: 'post',
    data
  })
}

// 公告内容创建
export function noticeContentCreate(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentCreate',
    method: 'post',
    data
  })
}

// 公告内容删除至回收站
export function noticeContentDeleteToRecycleBin(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentDeleteToRecycleBin',
    method: 'post',
    data
  })
}

// 公告内容彻底删除
export function noticeContentDelete(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentDelete',
    method: 'post',
    data
  })
}

// 公告撤销回草稿
export function noticeContentRevoke(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentRevoke',
    method: 'post',
    data
  })
}
// 公告还原
export function noticeContentRestore(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentRestore',
    method: 'post',
    data
  })
}

// 公告详情
export function noticeContentDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentDetail',
    method: 'post',
    data
  })
}
// 公告内容修改
export function noticeContentUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/noticeContentUpdate',
    method: 'post',
    data
  })
}


